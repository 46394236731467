import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";
require('dotenv').config();
class App extends Component {
  state = {usernameType:null}
  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
    this.onRouteChanged();
    console.log("------------------------------------------------------------------")
    this.getUserType()
  }

  componentWillUnmount() {
    // Remove event listener from the document when component unmounts
    document.removeEventListener('click', this.handleDocumentClick);
  }

    handleDocumentClick = (event) => {
    if(this.state.usernameType !=="anonymous"){
      fetch('/adminuser/getUsername-type')
          .then((response) =>  {
            if (response.status === 200)
            {
              response.json().then(res => {
                this.setState({usernameType:res.usernameType })
              })
            }
            else
            {
              window.location.href = '/login'
              this.setState({usernameType:"anonymous" })


            }
          })
          .catch(err => this.setState({usernameType:"anonymous" })
          );
    }
  };

  render () {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar/> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
    let SettingsPanelComponent = !this.state.isFullPageLayout ? <SettingsPanel/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    return (
      <div className="container-scroller">
        { navbarComponent }
        <div className="container-fluid page-body-wrapper">

          <div className="rwsidebar">
          { sidebarComponent }
          </div>
          <div className="main-panel">
            <div className="content-wrapper ">
              <AppRoutes usernameType = {this.state.usernameType}/>
              { SettingsPanelComponent }
            </div>

              { footerComponent }

          </div>
        </div>
      </div>
    );
  }
  getUserType() {
    fetch('/adminuser/getUsername-type')
        .then((response) =>  {
          if (response.status === 200)
          {
            response.json().then(res => {
              this.setState({usernameType:res.usernameType })
            })
          }
          else
          {
            this.setState({usernameType:"anonymous" })

          }
        })
        .catch(err => this.setState({usernameType:"anonymous" })
        );

  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      console.log("------------------------------------------------------------------")
      this.getUserType()
      this.onRouteChanged();

    }

  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
    const { i18n } = this.props;
    const body = document.querySelector('body');
    if(this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/signup/activateuser','/usermgmt/validate','/usermgmt/joinrdn','/usermgmt/uthk','/usermgmt/declinePage','/usermgmt/declineUser','/signup/entpwd','/signup/terms_of_use','/signup/privacy_policy','/signup/confirmplan','/signup/defaultrechargeamount','/signup/selectupgradetype','/signup/selectplantype','/signup/trigger-email-address-email','/signup/success','/signup/checkout-form','/signup/validate-email-address','/signup/set-password','/signup/thankyou','/login', '/user-pages/login-2', '/register','/fgtpwd', '/user-pages/user-register', '/user-pages/lockscreen', '/404', '/general-pages/landing-page'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        //RW code start
        document.querySelector('.rwsidebar').classList.remove('col-lg-2');
        document.querySelector('.main-panel').classList.remove('col-lg-10');
        //RW Code End
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
        //RW code start
        document.querySelector('.rwsidebar').classList.add('col-lg-2');
        document.querySelector('.main-panel').classList.add('col-lg-10');
        //RW Code End
      }
	 if (this.props.location.pathname === "/abc"){
		 window.location.href = '/rdn/404';
	 }
    }
  }

}

export default withTranslation()(withRouter(App));
